import { format } from 'date-fns'
import { HistoricalOrder } from '@sh24/admin-api-js'
import { ByYearAndDate, ButtonLink } from '@sh24/ui-components'

import useTranslations from '../../utils/use-translations'
import InlineLink from '../InlineLink/inline-link'
import ResultsHistoryCard from '../ResultsHistoryCard/results-history-card'

export const ViewResultsButton = ({ text, url }: { text: string, url: string }) => (
  <ButtonLink variation="primary" fullWidth iconPosition="none">
    <InlineLink
      text={text}
      url={url}
    />
  </ButtonLink>
)

const ResultsHistoryDisplay = ({ orders }: { orders: HistoricalOrder[] }) => {
  const translations = useTranslations()

  const renderOrderCard = (order: HistoricalOrder) => {
    const viewResultsButton = (
      <ViewResultsButton
        text={translations[`orderCard.viewResultsButtonText.${order.resultsState}`]}
        url={`/account/results/${order.sh24Uid}`}
      />
    )

    const createdAtLabel = order.resultsState === 'released' ? 'Results ready' : 'Updated at'

    return (
      <ResultsHistoryCard
        results={order.results}
        orderId={`Order ID: ${order.sh24Uid}`}
        status={`${createdAtLabel}: ${format(order.createdAt, 'dd LLLL yyyy')}`}
        cta={viewResultsButton}
      />
    )
  }

  const ordersDisplay = orders
    .sort(({ createdAt: a }, { createdAt: b }) => b.valueOf() - a.valueOf())
    .map((order, index) => (
      {
        date: order.createdAt,
        content: renderOrderCard(order),
        defaultOpen: index === 0,
      }
    ))

  return (
    <ByYearAndDate items={ordersDisplay} />
  )
}

export default ResultsHistoryDisplay
