import { BreakpointHelpers } from '@sh24/ui-components'
import styled from 'styled-components'
import { PrepSubscriptionPrepOrderData, PrepSubscriptionStiTestOrderData } from '@sh24/admin-api-js'
import PrepOrderStatus from './PrepOrderStatus/prep-order-status'
import StiTestOrderStatus from '../../OrderStatusCards/StiTestOrderStatus/sti-test-order-status'
import RepeatHivTestStatus from './RepeatHivTestStatus/repeat-hiv-test-status'

type StatusContainerTypes = {
  lastOrders: {
    prepOrder: PrepSubscriptionPrepOrderData | null,
    stiTestOrder: PrepSubscriptionStiTestOrderData | null,
  } | null,
  repeatHivTest?: boolean
}

const { mobileOnly, tabletUp } = BreakpointHelpers

const Container = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme?.spacing?.md};
    display: flex;
    height: auto;

    ${mobileOnly(`
      flex-direction: column;
      gap: ${theme?.spacing?.sm};
    `)}

    ${tabletUp(`
      flex-direction: row;
      justify-content: center;
      gap: ${theme?.spacing?.md};

      > div {
        width: 40%;
      }
    `)}
  `}
`
const StatusContainer = ({
  lastOrders,
  repeatHivTest = false,
}: StatusContainerTypes) => {
  const stiTestOrderStatusCard = lastOrders?.stiTestOrder && (
    <StiTestOrderStatus stiTestOrderData={lastOrders?.stiTestOrder} />
  )

  const repeatHivTestStatusCard = repeatHivTest && (
    <RepeatHivTestStatus />
  )

  return (
    <Container>
      {repeatHivTestStatusCard || stiTestOrderStatusCard}
      {lastOrders?.prepOrder && (<PrepOrderStatus prepOrderData={lastOrders.prepOrder} />)}
    </Container>
  )
}

export default StatusContainer
